import React, {useState} from 'react';
import {Link} from 'react-scroll';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import Logo from '../images/oliver_heesch_logo.webp'

const Navbar = () => {
    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    };

    return (
        <div className='sticky top-0 flex justify-between items-center h-18 max-w-1240px mx-auto px-4 bg-[#1D2634] text-white shadow-xl z-[100]'>
            <img className='w-[120px] m-4' src={Logo} alt='Oliver Heesch Marketing Consulting' />
            <ul className='hidden md:flex cursor-pointer'>
                <li className='p-2 hover:text-[#57757E]'><Link to="About" spy={true} smooth={true} offset={-50} duration={500}>About</Link></li>
                <li className='p-2 hover:text-[#57757E]'><Link to="Business" spy={true} smooth={true} offset={-50} duration={500}>Service</Link></li>
                <li className='p-2 hover:text-[#57757E]'><Link to="Experience" spy={true} smooth={true} offset={-50} duration={500}>Experience</Link></li>
                <li className='p-2 hover:text-[#57757E]'><a href="https://oheesch.notion.site/Oliver-Heesch-Portfolio-SNAPSHOT-4e309499bade4f7b972b786ca1b2b20f" target="_blank" rel="noreferrer">Portfolio</a></li>
                <li className='p-2 hover:text-[#57757E]'><Link to="Contact" spy={true} smooth={true} offset={-50} duration={500}>Connect</Link></li>
            </ul>
            <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
            </div>
            
            <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#1D2634] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
            <img className='w-[120px] m-4' src={Logo} alt='Oliver Heesch Marketing Consulting' />
                <ul className='uppercase p-4'>
                <li className='p-4 border-b border-white-900 hover:text-[#57757E]'><Link to="About" spy={true} smooth={true} offset={-50} duration={500}>About</Link></li>
                <li className='p-4 border-b border-white-900 hover:text-[#57757E]'><Link to="Business" spy={true} smooth={true} offset={-50} duration={500}>Service</Link></li>
                <li className='p-4 border-b border-white-900 hover:text-[#57757E]'><Link to="Experience" spy={true} smooth={true} offset={-50} duration={500}>Experience</Link></li>
                <li className='p-4 border-b border-white-900 hover:text-[#57757E]'><a href="https://oheesch.notion.site/Oliver-Heesch-Portfolio-SNAPSHOT-4e309499bade4f7b972b786ca1b2b20f" target="_blank" rel="noreferrer">Portfolio</a></li>
                <li className='p-4 hover:text-[#57757E]'><Link to="Contact" spy={true} smooth={true} offset={-50} duration={500}>Connect</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar;